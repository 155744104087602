// This code is a conversion of a webflow jQuery script that updates the faq's accordions

export const faq = () => {
    let accordions = document.querySelectorAll('[data-click="accordion"]');
    if (accordions.length > 0) {
        accordions.forEach((accordion) => {
            accordion.addEventListener("click", () => {
                if (!accordion.classList.contains("open")) {
                    document.querySelectorAll('[data-click="accordion"].open').forEach(function (item) {
                        item.click();
                    });
                    accordion.classList.add("open");
                } else {
                    accordion.classList.remove("open");
                }
            });
        });
    }

    return;
};
