// Allows the map to be interacted with by the user

export const map = () => {
    if (document.querySelector("[data-map-section]")) {
        let cityContainer = document.querySelector(".city-list");

        if (cityContainer) {
            let cityContainerContent = cityContainer.innerHTML; // Save the content for later

            // * A) Refresh the map to see a city
            let map;
            let registerLinks = () => {
                setTimeout(() => {
                    map = googleMaps.getMap("winslow-map");

                    // Zoom on the selected marker
                    let seeOnMapCtas = document.querySelectorAll("[data-marker]");
                    if (seeOnMapCtas.length > 0) {
                        for (let cta of seeOnMapCtas) {
                            cta.addEventListener("click", (e) => {
                                e.preventDefault();
                                // Remove the mobile panel if needed
                                document.querySelector('[data-close-panel]').click();
                                // and pan to the selected marker!
                                map.panToMarker(e.currentTarget.getAttribute("data-marker") + "-location")
                                    .zoom(12)
                                    .refresh();
                            });
                        }
                    }
                }, 300);
            };

            // Register links on load
            window.addEventListener("DOMContentLoaded", registerLinks);

            // * B) Search for cities by typing *
            let citySearch = document.querySelector("[data-city-search]");
            let call;
            async function cSearch(e) {
                clearTimeout(call);

                if (e.currentTarget.value === "") {
                    cityContainer.innerHTML = cityContainerContent;
                }

                let searchSize = e.currentTarget.value.length;
                let data = e.currentTarget.value;
                if (searchSize >= 3) {
                    call = setTimeout(async () => {
                        response = await fetch("/ajax/cities-search?search=" + data);
                        if (!response.ok) {
                            response = "error";
                        }
                        cityContainer.innerHTML = await response.text();
                        registerLinks();
                    }, 666);
                }
            }

            citySearch.addEventListener("input", cSearch);

            // * C) Search for cities in a specific region *
            let regionSearch = document.querySelectorAll("[data-region");
            if (regionSearch.length > 0) {
                async function rSearch(e) {
                    e.preventDefault();
                    response = await fetch("/ajax/cities-search?regionId=" + e.target.getAttribute("data-region"));
                    if (!response.ok) {
                        response = "error";
                    }
                    cityContainer.innerHTML = await response.text();
                    registerLinks();

                    // Close and update the dropdown
                    let dropdown = document.querySelector("[data-regions-dropdown]");
                    dropdown.querySelector("[data-selected-region]").textContent = e.target.textContent;
                    dropdown.dispatchEvent(new MouseEvent("mouseup"));
                }
                for (let current of regionSearch) {
                    current.addEventListener("click", rSearch);
                }
            }
        }
    }
    return;
};
