//// JS MAIN FILE ////
import * as htmx from "htmx.org";

// Import Webflow
import { webflow } from './modules/_webflow.js';
webflow();

// Import tags
import { envTags } from './ink-gsap/_envTags.js';
envTags();

// Import modules
import { favInit } from './modules/_favoris.js';
favInit();
import { payWait } from './modules/_payWait.js';
payWait();
import { party } from './modules/_party.js';
party();
import { print } from './modules/_print.js';
print();

// Import templates
import { qrLib } from './templates/_qrLib.js';
qrLib();
import { scan } from './templates/_scan.js';
scan();
import { events } from './templates/_events.js';
events();
import { faq } from './templates/_faq.js';
faq();
import { map } from './templates/_map.js';
map();

// Import gsap
import { animations } from './modules/_gsap.js';
animations();