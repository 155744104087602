export const favInit = () => {
  document.addEventListener('htmx:afterOnLoad', favInit);
  const favBtns = document.querySelectorAll("[fav-btn]");
  
  favBtns.forEach((favBtn) => {
    const coursFavoris = favBtn.getAttribute("fav-btn").split(",");
    
    favBtn.addEventListener("click", e => {
      e.preventDefault();
      e.stopPropagation();
      getSessionInfo().then((session) => {  
        let formData = new FormData();
        formData.append("action", "users/save-user");
        formData.append("userId", session.id);
        coursFavoris.forEach((coursId) => {
          formData.append("fields[coursFavoris][]", coursId);
        });
        
        fetch("/", {
          method: "POST",
          body: formData,
          headers: {
            Accept: "application/json",
            "X-CSRF-Token": session.csrfTokenValue,
          },
        }).then((response) => {
          if ((response.ok = true)) {
            htmx.trigger("[fav-reload]", "refresh");
          }
        });
      });
    }); 
  });
};

const getSessionInfo = function () {
  return fetch("/actions/users/session-info", {
    headers: {
      Accept: "application/json",
    },
  }).then((response) => response.json());
};
