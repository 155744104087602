// Tiny code detecting a click on a [data-print] to display the print prompt.

export const print = () => {

    let printCtas = document.querySelectorAll('[data-print]');

    if (printCtas.length > 0) {
        printCtas.forEach(current => {
            current.addEventListener('click', e => {
                e.preventDefault();
                e.stopPropagation();
                window.print();
            });
        });
    }

    return;
};
